import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { useOrderingHarvestRequestModalApi } from '@/api/hooks/outputs'
import { Button } from '@/components/ui'
import { isUserBuyer, isUserContentManager, isUserSupplier } from '@/logic/auth/reducer'
import { isCookieAgreed } from '@/logic/cookieAgreement/reducer'
import { RootState } from '@/redux/interfaces'
import { useHarvestSaleLotsPageParams } from '@/utils/outputs/useHarvestSaleLotsPageParams'
import { IOwnOfferButtonProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const mapState = (state: RootState) => ({
  isSupplier: isUserSupplier()(state),
  isBuyer: isUserBuyer()(state),
  isContentManager: isUserContentManager()(state),
  hasCookieAgreement: isCookieAgreed()(state),
})

const OwnOfferButton: FC<IOwnOfferButtonProps> = props => {
  const { className, textId, isInsideBlock = false, onButtonClick, sourceOfOpening, children } = props
  const { isSupplier, isBuyer, isContentManager, hasCookieAgreement } = useSelector(mapState)
  const { cultureId } = useHarvestSaleLotsPageParams()
  const { openOrderingHarvestRequestModal, isAuthenticated, isAgrarian } = useOrderingHarvestRequestModalApi({
    currentCultureId: cultureId,
    isOwnOffer: true,
    sourceOfOpening,
  })

  if (isSupplier || isBuyer || isContentManager) return null

  const handleButtonClick = () => {
    onButtonClick?.()
    openOrderingHarvestRequestModal()
  }

  return (
    <Button
      modifiers={['small', isInsideBlock ? 'white-darkGreen' : 'outline']}
      onClick={handleButtonClick}
      textId={textId}
      className={classNames(
        { 'own-offer__button': !isInsideBlock, 'own-offer__button_with-cookie-banner': !hasCookieAgreement && !isInsideBlock },
        className,
      )}
      isHidden={isAuthenticated && !isAgrarian}
    >
      {!textId && (children || messages.ownOfferButton)}
    </Button>
  )
}

export default OwnOfferButton
