import React, { FC } from 'react'
import classNames from 'classnames'
import { Button } from '@/components/ui'
import { ILotBannerProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const LotBanner: FC<ILotBannerProps> = ({ className, isMobileView, onButtonClick }) => {
  return (
    <section className={classNames('lotBanner', { lotBanner_mobileView: isMobileView }, className)}>
      <div className="lotBanner__titleWrapper">
        <span className="lotBanner__title">{messages.harvestSaleLots.lotBanner.title}</span>
      </div>
      <span className="lotBanner__description">{messages.harvestSaleLots.lotBanner.description}</span>
      <a className="lotBanner__action" href={'https://xn--80afpakcv0aj.xn--e1alid.xn--p1ai/logistics'}>
        <Button className="lotBanner__action_button" onClick={onButtonClick}>
          {messages.harvestSaleLots.lotBanner.button}
        </Button>
      </a>
    </section>
  )
}

export default LotBanner
