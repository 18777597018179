import React from 'react'
import { Checkbox, TOnCheckboxChange } from '@frontend/pole-ui/lib/components/Checkbox'
import { injectMessages } from '@/logic/translation/utils'
import { useMount } from '@/utils/hooks'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import ErrorMessage from '../../ErrorMessage'
import InjectHtml from '../../InjectHtml'
import { TCheckboxViewProps } from './interfaces'
import messages from './messages.json'

injectMessages(messages)

const CheckboxFieldView: React.FC<TCheckboxViewProps> = props => {
  const {
    input,
    label,
    labelId,
    onChange,
    shouldClearValueOnUnmount,
    meta,
    children,
    shouldChangeValue = true,
    errorClassName = 'space-holder-left40-from-sm space-holder-left24-sm space-holder-top8',
    ...otherProps
  } = props

  const { isMobile } = useDeviceType()

  useMount(undefined, () => {
    if (shouldClearValueOnUnmount) input.onChange(false)
  })
  const { touched, error } = meta

  const onCheckboxChange: TOnCheckboxChange = (_state, event) => {
    if (!shouldChangeValue) return

    onChange?.(event)
    input.onChange(event)
  }

  return (
    <Checkbox
      {...otherProps}
      onChange={onCheckboxChange}
      onFocus={otherProps.onFocus ?? input.onFocus}
      onBlur={input.onBlur}
      isMobile={isMobile}
      value={input.checked || input.value}
      error={touched && error && <ErrorMessage textId={`checkbox.error.${error}`} isVisible className={errorClassName} />}
    >
      {children || label || (labelId && <InjectHtml textId={labelId} />)}
    </Checkbox>
  )
}

export default CheckboxFieldView
