import { useContext, useEffect, useRef, useState } from 'react'
import omitBy from 'lodash/omitBy'
import reduce from 'lodash/reduce'
import { TemplatesFilterName } from '@/logic/harvestSale/constants'
import { HarvestSaleLotsContext } from '@/pages/HarvestSaleLots/constants'
import { ECultureId } from '@/types'

export const useGetFilterOptions = () => {
  const { onChangeFilter, selectedCultureId, resetFilter, isFilterChosen, onChangeFormValue, formValues, countOfSelectedFilters } =
    useContext(HarvestSaleLotsContext)
  const [isFilterShown, setFilterVisibility] = useState<boolean>(false)
  const tagsPortal = useRef<HTMLElement>()
  useEffect(() => {
    document.body.style.overflow = isFilterShown ? 'hidden' : ''
  }, [isFilterShown])

  const proteinValues = formValues[TemplatesFilterName.PROTEIN]
  const selectedProteinValues = Array.isArray(proteinValues)
    ? proteinValues?.toString()
    : Object.keys(omitBy(proteinValues, value => !value))?.toString()

  useEffect(() => {
    if (selectedProteinValues && !selectedCultureId?.includes(ECultureId.WHEAT) && onChangeFormValue) {
      onChangeFormValue(TemplatesFilterName.PROTEIN, {})
      onChangeFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCultureId])

  const closeFilter = () => setFilterVisibility(false)

  const onResettingFormButtonClick = () => {
    resetFilter()
    closeFilter()
  }

  const onFilterDropdownChange = (values: string[], filterName: string) => {
    onChangeFormValue?.(filterName, {
      ...reduce(values, (result, value) => ({ ...result, [value]: true }), {}),
    })
    onChangeFilter()
  }

  return {
    onResettingFormButtonClick,
    tagsPortal,
    onChangeFilter,
    isFilterChosen,
    closeFilter,
    setFilterVisibility,
    isFilterShown,
    onFilterDropdownChange,
    countOfSelectedFilters,
  }
}
