import React, { CSSProperties } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import { EIconName } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { Button, HangingMarker } from '@/components/ui'
import { getIndicators } from '@/logic/harvestSale/reducer'
import { pushGtmEventOnChangeFilterFormClick } from '@/logic/metrika/harvest/helpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { LeftFilter } from '@/pages/HarvestSaleLots/components'
import { RootState } from '@/redux/interfaces'
import { TQualityName } from '@/types/HarvestSaleLots'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { useGetFilterOptions } from '../useGetFilterOptions'
import CheckboxArray from './CheckboxArray/CheckboxArray'
import { transformIndicatorOptionsToCheckboxOptions } from './helpers'
import { messages } from './messages'

const mapState = (state: RootState) => ({
  indicators: getIndicators()(state),
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

const TopFilterTabletAndMobile: React.FC = () => {
  const {
    onResettingFormButtonClick,
    tagsPortal,
    onChangeFilter,
    closeFilter,
    setFilterVisibility,
    isFilterShown,
    countOfSelectedFilters,
  } = useGetFilterOptions()

  const { indicators, userGtmInfo, userRegion } = useSelector(mapState)

  const { isMobile, isTablet } = useDeviceType()

  const handleChangeFilter = (value: TQualityName) => {
    onChangeFilter()
    pushGtmEventOnChangeFilterFormClick(userGtmInfo, userRegion.name, value)
  }

  const TitleTag = isMobile ? 'h3' : 'h2'

  return (
    <div
      className={classNames('row row_nowrap', {
        'harvest-sale-page-top-filter': isTablet,
      })}
    >
      <Button
        className="harvest-sale-filter-toggle-button"
        onClick={() => setFilterVisibility(true)}
        iconPosition="right"
        iconProps={!countOfSelectedFilters ? { name: EIconName.Filter, className: 'harvest-sale-filter-toggle-button__icon' } : undefined}
        isUnstyled={isTablet}
        isTextInSpan={isTablet}
      >
        {Number(countOfSelectedFilters) > 0 ? (
          <HangingMarker modifier="filter">{countOfSelectedFilters}</HangingMarker>
        ) : (
          messages.tabletTitle
        )}
      </Button>
      <CSSTransition in={isFilterShown} classNames="fading" className="harvest-sale-filter-modal fading-enter" timeout={400} mountOnEnter>
        <section style={{ '--tag-background': EColor.LIGHT_GREEN } as CSSProperties}>
          <TitleTag className="space-holder32 space-holder24-sm">{messages.title}</TitleTag>
          <div className="harvest-sale-filter-modal__filters">
            {indicators.map(({ name, value, options }) => {
              return (
                <section key={`${value}-${name}`} className="checkboxes-filter">
                  <h5 className="space-holder24-from-sm space-holder16-sm">{name}</h5>
                  <CheckboxArray
                    className="checkboxes-filter__items"
                    name={value}
                    options={transformIndicatorOptionsToCheckboxOptions(options)}
                    onChange={() => handleChangeFilter(value)}
                  />
                </section>
              )
            })}
            <LeftFilter regionTagsPortal={tagsPortal.current} />
          </div>
          <div className="harvest-sale-filter-modal__actions text_center-only-md">
            <Button
              modifiers={isMobile ? ['outline-gray'] : ['outline-gray', 'small']}
              className="space-holder-right16-only-md harvest-sale-filter-modal__button"
              onClick={onResettingFormButtonClick}
            >
              {messages.buttons.resetFilter}
            </Button>
            <Button
              modifiers={isMobile ? ['green'] : ['green', 'small']}
              className="harvest-sale-filter-modal__button space-holder8-sm"
              onClick={closeFilter}
            >
              {messages.buttons.accept}
            </Button>
          </div>
          <Button
            className="harvest-sale-filter-modal__close-button"
            isUnstyled
            onClick={closeFilter}
            iconProps={{ name: EIconName.Exit }}
          />
        </section>
      </CSSTransition>
    </div>
  )
}

export default TopFilterTabletAndMobile
