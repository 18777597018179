import { TMessages } from '@/types/TMessages'

export const messages = {
  regions: {
    title: 'Регион покупки',
    placeholder: 'Регион',
  },
  elevatorOptions: {
    fcaDescription: 'Нет элеваторов для выбора, так как покупатель работает только по базису FCA (вывоз с хозяйства).',
    zero: 'Нет элеваторов в выбранных регионах',
    noSelectedRegions: 'Выберите регион для отображения элеваторов',
  },
  buyerOptions: {
    zero: 'Нет покупателей в выбранных регионах',
  },
} satisfies TMessages
