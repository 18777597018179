import React, { useState } from 'react'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import CheckboxFieldView from '@/components/ui/fields/CheckboxInput/CheckboxFieldView'
import { useEffectAfterMount } from '@/utils/hooks'
import { TCheckboxArrayViewProps } from './interfaces'

const meta = { touched: false, error: false }

const CheckboxArrayView: React.FC<TCheckboxArrayViewProps> = ({ onChange, input, options, className, dependency }) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(
    // eslint-disable-next-line prettier/prettier
    typeof input.value === 'string' && input.value ? [input.value] : (input.value || []),
  )

  const setValues = (newValues: string[]) => {
    input.onChange(newValues)
    onChange?.(newValues)
    setCheckedValues(newValues)
  }

  const onCheckboxButtonClick = (isActive: boolean, value: string) => {
    const newValues = isActive ? checkedValues.filter(checkedValue => checkedValue !== value) : [...checkedValues, value]
    setValues(newValues)
  }

  useEffectAfterMount(() => {
    if (!isEqual(input.value, checkedValues)) {
      setCheckedValues(input.value)
    }
  }, [input.value])

  return (
    <div className={className}>
      {options.map(({ value, name: optionName, dependencies }, i) => {
        if (dependency && dependencies?.length && !some(dependencies, dependency)) return null

        const isActive = checkedValues.includes(value)
        const inputObj = {
          checked: isActive,
          name: '',
          onBlur: () => null,
          onChange: () => onCheckboxButtonClick(isActive, value),
          onFocus: () => null,
          value: isActive,
        }

        return <CheckboxFieldView id={value} key={`${value}-${i}`} name="" input={inputObj} meta={meta} label={optionName} />
      })}
    </div>
  )
}

export default CheckboxArrayView
