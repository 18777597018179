import { TMessages } from '@/types/TMessages'

export const messages = {
  header: {
    multiple: 'Мультизаявка',
    solo: 'Конкретный покупатель',
  },
  description: {
    multiple: 'Заявку увидят все покупатели, они смогут сделать предложение в течение 24 часов',
    solo: 'В случае, если вы хотите работать с покупателем напрямую',
  },
  noBuyers: 'Не найдено покупателей по выбранной культуре и базису',
} satisfies TMessages
