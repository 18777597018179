import React, { useContext } from 'react'
import { StoreInput } from '@/components/composed'
import { IStoreLocation } from '@/types'
import { EPriceVariant } from '@/types/HarvestSaleLots'
import { getStoreValidator } from '@/utils/validators'
import { HarvestSaleLotsContext } from '../../../../constants'
import { messages } from './messages'

const StoreFilter: React.FC = () => {
  const { saveFilterValues, formValues } = useContext(HarvestSaleLotsContext)

  const onStoreChange = ({ id: locationId }: IStoreLocation) => {
    saveFilterValues({ ...formValues, store: locationId })
  }

  const isStoreRequired = formValues.sortBy === EPriceVariant.STORE

  return (
    <div className="space-holder-top8-md">
      <StoreInput
        name="store"
        label={messages.placeholder}
        dropdownProps={{
          modifiers: ['white'],
          dropdownModifiers: ['black'],
          className: 'harvest-sale-dropdown-filters__dropdown',
          customValidator: getStoreValidator(isStoreRequired),
          shouldBeTouchedBeforeError: false,
        }}
        onStoreChange={onStoreChange}
        onAddNewLocation={onStoreChange}
        position="page"
      />
    </div>
  )
}

export default StoreFilter
