import { requestData } from '@/api/request'
import { EHarvestSaleLotsApiUrl } from '@/api/urls'
import { createRouterLink, getUrlWithQuery } from '@/utils/url'
import { ICreateHarvestSaleDraft, IHarvestSaleDraft } from './interfaces'

class DraftService {
  private draftUrl = (cultureId: string) => {
    return createRouterLink(EHarvestSaleLotsApiUrl.draftRequest, { cultureId })
  }
  private getDraftUrl = (cultureId: string) => {
    return getUrlWithQuery(EHarvestSaleLotsApiUrl.getDraftRequest, { cultureId })
  }
  getDraft = (cultureId: string): Promise<IHarvestSaleDraft> => {
    return requestData<IHarvestSaleDraft>({
      url: this.getDraftUrl(cultureId),
      method: 'GET',
    })
      .then(({ data }) => data || ({} as IHarvestSaleDraft))
      .catch(() => ({} as IHarvestSaleDraft))
  }

  createDraft = (payload: ICreateHarvestSaleDraft) => {
    return requestData({
      url: this.draftUrl(payload.cultureId),
      method: 'POST',
      body: payload,
    })
  }

  updateDraft = (payload: ICreateHarvestSaleDraft) => {
    return requestData({
      url: this.draftUrl(payload.cultureId),
      method: 'PATCH',
      body: payload,
    })
  }
}

export default DraftService
