import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@/components/ui'
import { CheckboxButtons } from '@/components/ui/fields'
import { getIndicators } from '@/logic/harvestSale/reducer'
import { pushGtmEventOnChangeFilterFormClick } from '@/logic/metrika/harvest/helpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { HarvestSaleLotsContext } from '@/pages/HarvestSaleLots/constants'
import { RootState } from '@/redux/interfaces'
import { transformIndicatorOptionsToCheckboxButtonsOptions } from './helpers'
import '../styles.scss'

const mapState = (state: RootState) => ({
  indicators: getIndicators()(state),
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

const TopFilterDesktop: React.FC = () => {
  const { indicators, userGtmInfo, userRegion } = useSelector(mapState)

  const { onChangeFilter, formValues = {}, saveFilterValues, savedFilterValues } = useContext(HarvestSaleLotsContext)

  const resetFilter = (name: string) => () => {
    saveFilterValues({ ...savedFilterValues, [name]: [] })
    onChangeFilter()
  }

  if (!indicators.length) return null

  return (
    <div className="harvest-sale-top-filter-wrapper">
      <div className="harvest-sale-top-filter">
        {indicators.map(({ name, value, options }) => {
          const shouldShowResetButton = !!formValues?.[value]?.length

          return (
            <div key={`${name}-${value}`} className="harvest-sale-top-filter-item-wrap">
              <div className="harvest-sale-top-filter-item">
                <h5 className="harvest-sale-top-filter-item__title">{name}</h5>
                <CheckboxButtons
                  className="harvest-sale-top-filter-item__buttons"
                  name={value}
                  buttonClassName="harvest-sale-top-filter-item__button"
                  options={transformIndicatorOptionsToCheckboxButtonsOptions(options)}
                  onChange={() => {
                    onChangeFilter()
                    pushGtmEventOnChangeFilterFormClick(userGtmInfo, userRegion.name, value)
                  }}
                >
                  {shouldShowResetButton && (
                    <Button
                      modifiers={['circle-cross']}
                      className="harvest-sale-top-filter-item__reset shrink_no harvest-sale-top-filter-item__button"
                      onClick={resetFilter(value)}
                    />
                  )}
                </CheckboxButtons>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TopFilterDesktop
