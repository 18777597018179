import { ECultureId } from '@/types'
import { IRecommendedPrice } from './interfaces'

interface IGetActualRecommendedPriceProps {
  cultureId: string
  prices?: IRecommendedPrice[]
  quality: Record<string, any>
}

export const getActualRecommendedPrice = (props: IGetActualRecommendedPriceProps): IRecommendedPrice | undefined => {
  const { cultureId, prices, quality } = props
  const searchQualityValue = quality?.proteinFrom

  if (cultureId !== ECultureId.WHEAT) return prices?.[0]

  if (!searchQualityValue || !prices) return undefined

  const filteredByProteinPrices = prices.filter(price =>
    price.qualityParams.indicators?.proteinFrom?.possibleValues.includes(searchQualityValue),
  )

  return filteredByProteinPrices?.[0]
}
