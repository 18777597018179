import { useGetApi } from '@/api/hooks'
import { IRecommendedPrice } from '@/api/hooks/outputs/interfaces'
import { EDeliveryBasisVariantId } from '@/types/HarvestSaleLots'
import { getActualRecommendedPrice } from './helpers'

interface IUseRecommendedPrice {
  cultureId: string
  deliveryBasisId: EDeliveryBasisVariantId
  regionCode?: number
  storeId?: string
  quality?: Record<string, any>
}

export const useRecommendedPrice = ({ cultureId, regionCode, deliveryBasisId, storeId, quality = {} }: IUseRecommendedPrice) => {
  const shouldFetchRecommendedPrices = Boolean(cultureId && regionCode && deliveryBasisId === EDeliveryBasisVariantId.pickup && storeId)

  const { data: prices } = useGetApi<IRecommendedPrice[]>(
    `/api/price/analyzed/culture/${cultureId}/regionCode/${regionCode}`,
    [regionCode, cultureId, deliveryBasisId],
    { shouldMakeRequest: shouldFetchRecommendedPrices },
  )

  const recommendedPrice = getActualRecommendedPrice({ cultureId, prices, quality })

  // Отображаем цену, если она ненулевая, выбраны культура, регион, склад и базис = FCA
  const shouldDisplayPrice = shouldFetchRecommendedPrices && Boolean(recommendedPrice?.fcaPrice.value)

  return {
    recommendedPrice,
    shouldFetchRecommendedPrices,
    shouldDisplayPrice,
  }
}
