import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import find from 'lodash/find'
import { getRegions, getUserRegion } from '@/logic/userRegion'
import { LotsRegionCodesStorage } from '@/pages/HarvestSaleLots/helpers'
import { RootState } from '@/redux/interfaces'

const mapState = (state: RootState) => ({
  userRegion: getUserRegion()(state),
  regions: getRegions(state),
})

export const useCurrentRegion = () => {
  const { userRegion, regions } = useSelector(mapState)

  const region = useMemo(
    () => find(regions, { code: Number(LotsRegionCodesStorage.get()?.[0] || userRegion?.code) }),
    [userRegion, regions],
  )

  return {
    region,
  }
}
