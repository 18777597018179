import React from 'react'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classNames from 'classnames'
import { Notice, RadioButton } from '@/components/ui'
import { EOrderType, TOrderType } from '@/types/HarvestSaleLots'
import CustomLabel from './CustomLabel'
import { IHarvestRequestTypeRadioProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const orderTypes: TOrderType[] = Object.values(EOrderType)

const HarvestRequestTypeRadio: React.FC<IHarvestRequestTypeRadioProps> = props => {
  const { formValues, haveNoBuyers } = props
  const { orderType } = formValues || {}

  return (
    <div className="harvestSaleOrderRadio">
      {orderTypes.map(value => (
        <RadioButton
          key={value}
          value={value}
          name="orderType"
          className={classNames('harvestSaleOrderRadio__item', {
            'harvestSaleOrderRadio__item--checked': orderType === value,
          })}
          CustomLabel={<CustomLabel type={value} />}
        />
      ))}
      {haveNoBuyers && (
        <Notice className="harvestSaleOrderRadio__notice">
          <div className="harvestSaleOrderRadio__noticeTitle">
            <Icon name={EIconName.InfoFill} className="harvestSaleOrderRadio__noticeIcon" />
            <div>{messages.noBuyers}</div>
          </div>
        </Notice>
      )}
    </div>
  )
}

export default HarvestRequestTypeRadio
