import React from 'react'
import classNames from 'classnames'
import { InjectHtml } from '@/components/ui'
import { Dropdown } from '@/components/ui/fields'
import { IDropdownBuyersProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const DropdownBuyers: React.FC<IDropdownBuyersProps> = props => {
  const { options, isDisabled = false, className, onChange, onOpen } = props

  return (
    <Dropdown
      name="buyerId"
      options={options}
      placeholder={messages.requestHarvestSaleModal.buyer.label}
      isRequiredImportant
      onOpen={onOpen}
      isDisabled={isDisabled}
      className={classNames({ 'harvest-sale-dropdown_customized-footer': !options.length }, className)}
      DropdownListFooter={
        options.length
          ? undefined
          : () => <InjectHtml html={messages.requestHarvestSaleModal.buyer.notBeenFound} TagName="h5" className="text_center" />
      }
      onChange={onChange}
    />
  )
}

export default DropdownBuyers
