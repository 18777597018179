import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '@/components/composed/Modal/actions'
import { getOption } from '@/components/ui/fields/helpers/Dropdown'
import { isUserAgrarian } from '@/logic/auth/reducer'
//eslint-disable-next-line max-len
import { IRequestHarvestSaleByTemplateModalOptions } from '@/pages/HarvestSaleLots/components/RequestHarvestSaleByTemplateModal/interfaces'
import { RootState } from '@/redux/interfaces'
import { useActionWithAuthorization } from '@/utils/hooks/useActionWithAuthorization'
import { useCurrentRegion } from '@/utils/outputs/currentRegion'
import { IUseOrderingHarvestRequestModalApiOptions } from './interfaces'

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
})

export const useOrderingHarvestRequestModalApi = (options?: IUseOrderingHarvestRequestModalApiOptions) => {
  const { currentCultureId, sourceOfOpening, isOwnOffer = false } = options || {}
  const dispatch = useDispatch()
  const { region } = useCurrentRegion()
  const { isAgrarian } = useSelector(mapState)

  const { makeAuthorizedAction, isAuthenticated } = useActionWithAuthorization(
    () =>
      dispatch(
        openModal({
          options: {
            dialogId: 'RequestHarvestSaleByTemplateModal',
          },
          contentOptions: {
            defaultRegionOption: region && getOption(region.name, region.code),
            isOwnOffer,
            currentCultureId,
            blockId: 'createApplication',
            sourceOfOpening,
          } as IRequestHarvestSaleByTemplateModalOptions,
        }),
      ),
    isAgrarian,
  )

  return {
    openOrderingHarvestRequestModal: makeAuthorizedAction,
    isAuthenticated,
    isAgrarian,
  }
}
